import { useTranslations } from 'next-intl';
import { useState } from 'react';
import cn from 'classnames';
import s from './blog.module.scss';
import useBlog, { pageSize } from '../../hooks/useBlog';
import { Pagination } from '@ee-monorepo/shared/ui/pagination';
import { BreadCrumb } from '@ee-monorepo/shared/ui/breadcrumb';
import { BlogCategoriesDropDown, BlogCategoriesList } from './blog-categories';
import BlogSearchInput from './blog-search';
import { useHandHeld, useTablet } from '@ee-monorepo/shared/utilities/hooks';
import { BlogCard } from './blog-card/blog-card';
import { BlogTags } from './blog-tags';
import { useRouter } from 'next/router';
import { NoBlogResults } from './no-results';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { Skeleton } from '../lazy/skeleton';
import { colors } from '@snap/colors';
import { BreadcrumbJsonLd } from 'next-seo';

interface BlogProps {
  categories: Category[];
  selectedCategory?: SelectedCategory;
  documentTagsFromPrismic: string[];
  blog_image?: PrismicFields.Image;
  blogType?: typeForBlogType;
}

export type typeForBlogType = 'business-blog' | 'newsroom' | 'blog';

export interface Category {
  uid: string;
  label_category: string;
  title_color: string;
}

export interface SelectedCategory {
  id: string;
  title_color: string;
  name: string;
}

function Blog(props: BlogProps) {
  const { categories, selectedCategory, documentTagsFromPrismic, blogType } =
    props;
  const t = useTranslations();
  const {
    query: { pageNumber: _, ...otherQueryParams },
  } = useRouter();
  const { results, isLoading, pageNumber, setPageNumber } = useBlog(
    selectedCategory,
    blogType
  );

  const [collapsed, setExpanded] = useState(true);
  const updatePageNumber = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const isMobile = useHandHeld();
  const isTablet = useTablet();

  const getBlogTitle = (blogType: typeForBlogType) => {
    return blogType === 'newsroom'
      ? t('page.news_blog_title')
      : blogType === 'business-blog'
      ? t('page.business_blog_title')
      : t('page.blog_title');
  };

  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';

  return (
    <>
      <div className="mb-4 md:mb-16 py-8 md:py-12 bg-SnapDarkBlue text-SnapWhite">
        <div className="snap-container">
          {selectedCategory && selectedCategory.name ? (
            <div className="flex [&>h2]:ml-3">
              <h1
                className="mb-0 headingL md:headingXL"
                data-testid="category-selected-title"
              >
                {selectedCategory.name}
              </h1>
              <h2
                className="mb-0 headingL md:headingXL"
                data-testid="category-title"
              >
                {getBlogTitle(blogType)}
              </h2>
            </div>
          ) : (
            <h1
              className="mb-0 headingL md:headingXL"
              data-testid="category-title"
            >
              {getBlogTitle(blogType)}
            </h1>
          )}
        </div>
      </div>
      <div className="snap-container">
        {selectedCategory && !isMobile && (
          <div className="mb-5 md:mb-10 ml-2" data-testid="category-breadcrumb">
            {blogType && selectedCategory ? (
              <BreadcrumbJsonLd
                itemListElements={[
                  {
                    position: 1,
                    item: {
                      '@id': `${origin}/${blogType}`,
                      name: getBlogTitle(blogType),
                    },
                  },
                  {
                    position: 2,
                    item: {
                      '@id': `${origin}/${blogType}/${selectedCategory?.name.toLowerCase()}`,
                      name: selectedCategory?.name,
                    },
                  },
                ]}
                scriptId="blog-breadcrumb-seo"
              />
            ) : null}
            <BreadCrumb
              url={`/${blogType}`}
              label={getBlogTitle(blogType)}
              icon
            />
            <BreadCrumb label={selectedCategory.name} icon={false} />
          </div>
        )}
        <div className="row block md:hidden">
          <div
            className={cn(s.container, 'flex', 'w-full', {
              [s.collapsed]: collapsed,
            })}
          >
            <div className={cn(s.category, 'mr-2')}>
              <BlogCategoriesDropDown
                categories={categories}
                selectedCategory={selectedCategory}
                blogType={blogType}
              />
            </div>
            <div className={cn(s.keyword, 'ml-2')}>
              <BlogSearchInput
                onClick={() => setExpanded(!collapsed)}
                collapsed={collapsed}
                isMobile={isMobile}
                selectedCategory={selectedCategory}
                blogType={blogType}
              />
            </div>
          </div>
          <div>
            <BlogTags
              documentTagsFromPrismic={documentTagsFromPrismic}
              selectedTagsFromQuery={results.tags}
            />
          </div>
        </div>
        <div className="flex sm:justify-center row p-0 m-0">
          <div className="md:w-2/3 p-0 m-0 pr-0 md:pr-8" data-testid="blog">
            {isLoading ? (
              <div>
                <Skeleton height={160} />
                <Skeleton height={160} />
                <Skeleton height={160} />
                <Skeleton height={160} />
              </div>
            ) : (
              <>
                {results.currentPageData.length === 0 && <NoBlogResults />}
                <div>
                  <div className="container mx-auto">
                    <div className="flex flex-wrap justify-between">
                      {results.currentPageData.map((result, i) => (
                        <BlogCard
                          blogType={blogType}
                          category={result.category}
                          background_color={result?.category?.background_color ?? colors.SnapWhite}
                          uid={result.uid}
                          topic={result.topic}
                          summary={result.summary}
                          release_date={result.release_date}
                          blog_image={result.blog_image}
                          key={i}
                          className="mb-8"
                        />
                      ))}
                    </div>
                  </div>
                  {results.currentPageData.length > 0 && (
                    <Pagination
                      testId="blog-pagination"
                      totalItemCount={results.total}
                      pageSize={pageSize}
                      currentPageNumber={pageNumber}
                      setPageNumber={updatePageNumber}
                      generateQueryParam={(pageNumber) => {
                        const params = new URLSearchParams({
                          ...otherQueryParams,
                          pageNumber: `${pageNumber}`,
                        } as Record<string, string>);
                        return `?${params.toString()}`;
                      }}
                      renderFootNote={(
                        totalItems,
                        firstIndexOfPage,
                        lastIndexOfPage
                      ) => {
                        return t('page.number_of_articles', {
                          index: `${firstIndexOfPage}-${lastIndexOfPage}`,
                          totalCount: totalItems,
                        });
                      }}
                      variation={isTablet ? 'stack' : 'row'}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="md:w-1/3 hidden md:block border-l pl-8">
            <BlogSearchInput
              onClick={() => setExpanded(!collapsed)}
              collapsed={collapsed}
              isMobile={isMobile}
              selectedCategory={selectedCategory}
              blogType={blogType}
            />
            <div className="mt-6">
              <BlogCategoriesList
                categories={categories}
                selectedCategory={selectedCategory}
                blogType={blogType}
              />
            </div>
            <BlogTags
              documentTagsFromPrismic={documentTagsFromPrismic}
              selectedTagsFromQuery={results.tags}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
