import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { MenuItem, ListItemText } from '@mui/material';
import cn from 'classnames';
import {
  ColoredTag as Tag,
  TagBackgroundColor,
} from '@ee-monorepo/shared/ui/tag';
import s from './blog.module.scss';
import { Category, SelectedCategory, typeForBlogType } from './blog';
import { Select } from '@ee-monorepo/shared/ui/select';
import { useRouter } from 'next/router';
import { FormControlStyled } from '@ee-monorepo/shared/ui/field-control';

interface CategoriesProps {
  categories: Category[];
  selectedCategory?: SelectedCategory;
  blogType?: typeForBlogType;
}

interface CategoryProps {
  category: Category;
  selectedCategory?: SelectedCategory;
  blogType?: typeForBlogType;
}

function BlogCategoryLink(props: CategoryProps) {
  return (
    <Link
      href={`/${props.blogType}/${props.category.uid}`}
      data-testid="category-filter-tag"
      className={cn('text-decoration-none')}
    >
      <Tag
        text={props.category.label_category.toUpperCase()}
        backgroundColor={
          !props.selectedCategory ||
          props.selectedCategory.name !== props.category.label_category
            ? TagBackgroundColor['grey-100']
            : TagBackgroundColor[props.selectedCategory.title_color]
        }
        hoverBackgroundColor={TagBackgroundColor[props.category.title_color]}
        className="mb-4 [&>span]:tagLargeFont"
      />
    </Link>
  );
}

export function BlogCategoriesList(props: CategoriesProps) {
  const t = useTranslations();
  const categoriesTitle = t('page.categories_title');
  return (
    <div className="pb-4 border-b-1 border-SnapGrey300">
      <div className="bodyHeavyFont mb-5">{categoriesTitle}</div>
      {props?.categories?.map((category, i) => (
        <BlogCategoryLink
          key={i}
          category={category}
          selectedCategory={props.selectedCategory}        
          blogType={props.blogType}
        />
      ))}
    </div>
  );
}

export function BlogCategoriesDropDown(props: CategoriesProps) {
  const router = useRouter();
  const { push } = router;
  const t = useTranslations();
  const defaultCategory = t('page.default_category');
  return (
    <FormControlStyled className="w-full mb-4">
      <Select
        value={
          (props.selectedCategory && props.selectedCategory.name) ||
          defaultCategory
        }
        data-testid="category-select"
        className={cn(s.categorySelectDropdown, 'w-full')}
        inputProps={{ className: 'no-label' }}
        renderValue={(selected: string) => {
          return selected;
        }}
        noLabel
      >
        <MenuItem value={defaultCategory} onClick={() => push(`/${props.blogType}/`)}>
          {defaultCategory}
        </MenuItem>
        {props?.categories?.map((category, i) => (
          <MenuItem
            key={i}
            value={category.label_category}
            onClick={() => push(`/${props.blogType}/${category.uid}`)}
          >
            <ListItemText primary={category.label_category} />
          </MenuItem>
        ))}
      </Select>
    </FormControlStyled>
  );
}
