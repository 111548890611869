import React from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { formatDate } from '@ee-monorepo/shared/utilities/functions';
import s from './blog-card.module.scss';
import { SolidCard } from '@ee-monorepo/shared/ui/card';
import { ColoredTag as Tag } from '@ee-monorepo/shared/ui/tag';
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { useTranslations } from 'next-intl';
import { PrismicText } from '@prismicio/react';

export function BlogCard({
  blogType,
  category,
  background_color,
  uid,
  topic,
  summary,
  release_date,
  className,
  blog_image,
}) {
  const t = useTranslations();
  return (
    <SolidCard
      data-testid="blogCardItem"
      borderOnHover={true}
      className="w-full md:w-[48%] cursor-pointer mb-[2rem]"
    >
      <Link href={`/${blogType}/${category.slug}/${uid}`}>
        <ImageWrapper
          src={
            blog_image?.url
              ? blog_image.url
              : '/assets/cms/article_placeholder.jpg'
          }
          alt={blog_image?.url ? blog_image.alt : t('shared.blog_image_alt')}
          width={blog_image?.url ? blog_image.dimensions?.width : 342}
          height={blog_image?.url ? blog_image.dimensions?.height : 223}
          className="w-full"
          enableVendorOptimization
        />

        <div className="mt-4">
          <Tag
            backgroundColor={background_color}
            text={category.slug.replace('-', ' ')}
            className="mb-4"
          />
        </div>

        <div
          className={cn(
            'tagLargeFont',
            'uppercase text-SnapGrey400  mt-4 mb-3'
          )}
        >
          {formatDate(release_date)}
        </div>
        <div className="w-11/12">
          <a
            href={`/${blogType}/${category.slug}/${uid}`}
            className="text-SnapGrey500 headingSHeavy md:headingMHeavy hover:no-underline"
          >
            {<PrismicText field={topic} />}
          </a>
          <div
            className={cn(
              s.related_summary_main,
              'flex items-end mt-2 mb-4',
              'text-SnapGrey400'
            )}
            data-more-label={t('shared.more_label')}
          >
            <div className={cn(s.related_summary, 'secondaryFont md:bodyFont')}>
              {<PrismicText field={summary} />}
            </div>
          </div>
        </div>
      </Link>
    </SolidCard>
  );
}
