import React, { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as Prismic from '@prismicio/client';
import SearchIcon from '@mui/icons-material/Search';
import { createClient } from '../../prismic-configuration';
import cn from 'classnames';
import s from './blog.module.scss';
import { useDebounce } from '@ee-monorepo/shared/utilities/hooks';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslations } from 'next-intl';
import { PrismicDocument } from '@prismicio/types';
import * as prismicH from '@prismicio/helpers';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { typeForBlogType } from './blog';
import SearchBoxSchema from '../schema/schema-searchbox';

const useBlogActions = () => {
  const { push } = useRouter();
  const pushBlogTopic = useCallback(
    (title: string) => {
      const urlWithQueryParams = title
        ? `${document.location.pathname}?search=${title?.replace(/ /g, '+')}`
        : `${document.location.pathname}`;
      push(urlWithQueryParams);
    },
    [push]
  );
  return {
    pushBlogTopic,
  };
};

interface BlogSearchInputProps {
  isMobile: boolean;
  onClick?: () => void;
  collapsed?: boolean;
  selectedCategory: SelectedCategory;
  blogType?: typeForBlogType;
}

export interface SelectedCategory {
  id: string;
  title_color: string;
  name: string;
}

export default function BlogSearchInput(props: BlogSearchInputProps) {
  const t = useTranslations();
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchClicked, setSearchclicked] = useState(null);
  const inputValueDebounced = useDebounce(searchText, 800);
  const { pushBlogTopic } = useBlogActions();
  const inputRef = useRef(null);
  const { push } = useRouter();
  const validateURL = (url: string) => {
    const urlLength = url?.split('/');
    if (urlLength?.length === 3) {
      push(`/blog${url}`);
      return;
    }
    push(url);
    return;
  };

  useEffect(() => {
    const client = createClient();
    const pageSize = 5;
    async function fetchDocuments() {
      const queryPredicates = [
        Prismic.predicate.at('document.type', 'learning_topic'),
        Prismic.predicate.fulltext('document', inputValueDebounced),
      ];
      if (props.selectedCategory) {
        queryPredicates.push(
          Prismic.Predicates.at(
            'my.learning_topic.category',
            props.selectedCategory.id
          )
        );
      }
      return client.get({
        predicates: queryPredicates,
        pageSize,
      });
    }
    if (inputValueDebounced.length >= 3) {
      fetchDocuments().then((res) => {
        setSearchResults(res.results);
      });
    } else {
      setSearchResults([]);
    }
  }, [inputValueDebounced, props.selectedCategory]);

  return (
    <div
      className={cn(s.container, 'relative')}
      data-testid="blog-input-search"
    >
      <SearchBoxSchema page="blog" url={isSearchClicked} />
      {props.isMobile && (
        <a
          data-testid="input-search-toggle"
          onClick={() => {
            if (props.onClick) props.onClick();
            if (props.collapsed) {
              return inputRef.current.focus();
            }
            inputRef.current.blur();
          }}
          className={cn(s.searchIcon, {
            'text-SnapGrey400': !props.collapsed,
          })}
        >
          <SearchIcon />
        </a>
      )}
      {!props.isMobile && (
        <div className={cn(s.autoCompleteContainer)}>
          <span className={s.searchIcon}>
            <SearchIcon className={'text-SnapGrey400'} />
          </span>
        </div>
      )}
      <Autocomplete
        data-testid="autocomplete"
        onKeyDown={(e) => {
          if (e.which === 13 && searchText !== '') {
            pushBlogTopic(searchText);
            setSearchclicked(pushBlogTopic(searchText));
            inputRef.current.blur();
          }
        }}
        clearIcon={null}
        popupIcon={null}
        getOptionLabel={(document: Partial<PrismicDocument>) =>
          prismicH.asText(document.data.topic)
        }
        isOptionEqualToValue={(
          document: Partial<PrismicDocument>,
          value: Partial<PrismicDocument>
        ) => {
          return document.uid === value.uid;
        }}
        filterOptions={(x) => x}
        options={searchResults}
        noOptionsText={
          <div className="text-center p-0 m-0">
            <b className="mb-1">{t('shared.blog_input_no_results')}</b>
            <p>{t('shared.blog_input_no_options_footer')}</p>
          </div>
        }
        autoComplete
        inputValue={searchText}
        includeInputInList
        onChange={(_, newValue: Partial<PrismicDocument>) => {
          setSearchclicked(newValue?.uid);
          validateURL(newValue?.url);
        }}
        onInputChange={(_, newInputValue) => {
          setSearchText(newInputValue);
        }}
        className={s.searchInput}
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            {...params}
            fullWidth
            label={'Search'}
            className={cn(s.searchInput, {
              [s.searchInputCollapsed]: props.isMobile && props.collapsed,
            })}
          />
        )}
      />
    </div>
  );
}
