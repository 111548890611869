import { calculateNumberOfPages } from './helper';
import cn from 'classnames';
import { ListOfPages } from './list-of-pages';

interface PaginationProps {
  totalItemCount: number;
  pageSize: number;
  currentPageNumber: number;
  setPageNumber: (number: number) => void;
  generateQueryParam: (number: number) => string;
  testId: string;
  renderFootNote?: (
    totalItems: number,
    firstIndexOfPage: number,
    lastIndexOfPage: number
  ) => string;
  variation?: 'stack' | 'row';
}

export const Pagination = ({
  totalItemCount,
  pageSize,
  currentPageNumber,
  setPageNumber,
  generateQueryParam,
  renderFootNote,
  testId,
  variation = 'stack',
}: PaginationProps) => {
  const numberOfPages = calculateNumberOfPages(totalItemCount, pageSize);
  const setPageNumberAndResetScreen = (pageNumber: number) => {
    setPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };
  const firstIndexOfPageItems = pageSize * (currentPageNumber - 1) + 1;
  let secondIndexOfPageItems = firstIndexOfPageItems + pageSize - 1;
  secondIndexOfPageItems =
    secondIndexOfPageItems < totalItemCount
      ? secondIndexOfPageItems
      : totalItemCount;

  return (
    <div
      className={
        variation === 'row'
          ? 'flex justify-between flex-row-reverse mx-5'
          : null
      }
      data-testid={testId}
    >
      {numberOfPages > 1 ? (
        <ListOfPages
          numberOfPages={numberOfPages}
          currentPageNumber={currentPageNumber}
          setPageNumberAndResetScreen={setPageNumberAndResetScreen}
          generateQueryParam={generateQueryParam}
          variation={variation}
        />
      ) : null}
      {totalItemCount > 0 ? (
        <div className={variation === 'stack' ? 'text-center mt-2' : null}>
          <span className={cn('secondaryFont', 'text-SnapGrey500')}>
            {renderFootNote
              ? renderFootNote(
                  totalItemCount,
                  firstIndexOfPageItems,
                  secondIndexOfPageItems
                )
              : null}
          </span>
        </div>
      ) : null}
    </div>
  );
};
