import { SiteLinksSearchBoxJsonLd } from 'next-seo';
import { useRouter } from 'next/router';

export default function SearchBoxSchema({ page, url }) {
  const router = useRouter();
  // we have search incorporated on find-store page and the blog page
  // find-store page has & sign whereas blog has ? sign for search in URL
  const sign = page === 'store' ? '&search=' : '?search=';
  const hasSearchParam = url ? url?.replace(/-/g, ' ') : router.query.search;
  // storing domain url
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  // checking whether search params present
  return hasSearchParam && hasSearchParam !== '' ? (
    <SiteLinksSearchBoxJsonLd
      scriptId="schema-searchbox"
      data-testid="schema-searchbox"
      url={origin}
      potentialActions={[
        {
          target: `${origin}${router.asPath.split(sign)[0]}?search`, // spliting asPath and taking needed URL
          queryInput: `${hasSearchParam}`, // passing the searched value
        },
      ]}
    />
  ) : null;
}
