import Link from 'next/link';
import cn from 'classnames';
import s from './list-of-pages.module.scss';
import {
  KeyboardArrowRight as ArrowRightIcon,
  KeyboardArrowLeft as ArrowLeftIcon,
} from '@mui/icons-material';

interface ListOfPagesProps {
  numberOfPages: number;
  currentPageNumber: number;
  setPageNumberAndResetScreen: (currentPageNumber) => void;
  generateQueryParam: (number) => string;
  variation?: 'stack' | 'row';
}

export const ListOfPages = ({
  numberOfPages,
  currentPageNumber,
  setPageNumberAndResetScreen,
  generateQueryParam,
  variation = 'stack',
}: ListOfPagesProps) => {
  let listOfPages = [];
  if (numberOfPages === 1) return null;
  else if (numberOfPages <= 5) {
    for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
      listOfPages.push(
        <li
          data-testid={
            currentPageNumber === pageNumber
              ? 'selected-page'
              : `page-option-${pageNumber}`
          }
          className={cn('w-full', {
            'bg-SnapBlue': currentPageNumber === pageNumber,
            [s.firstItemContainer]: pageNumber === 1 && currentPageNumber === 1,
            [s.lastItemContainer]:
              pageNumber === numberOfPages &&
              currentPageNumber === numberOfPages,
          })}
          key={pageNumber}
        >
          <Link
            href={generateQueryParam(pageNumber)}
            shallow={true}
            className={cn(
              'flex items-center justify-center text-SnapGrey400 secondaryHeavyFont visited:text-SnapGrey400 hover:no-underline hover:text-SnapWhite hover:bg-SnapMediumBlue',
              {
                'text-SnapWhite visited:text-SnapWhite':
                  currentPageNumber === pageNumber,
                [s.firstItem]: pageNumber === 1 && currentPageNumber === 1,
                [s.lastItem]:
                  pageNumber === numberOfPages &&
                  currentPageNumber === numberOfPages,
              },
              'w-full',
              'h-full',
              s.border
            )}
            onClick={(_) => {
              setPageNumberAndResetScreen(pageNumber);
            }}
          >
            {pageNumber}
          </Link>
        </li>
      );
    }
  } else {
    let pages = [];
    if (
      currentPageNumber === 1 ||
      currentPageNumber === 2 ||
      currentPageNumber === 3
    )
      pages = [1, 2, 3, 4, '...', numberOfPages];
    else if (
      currentPageNumber === numberOfPages ||
      currentPageNumber === numberOfPages - 1 ||
      currentPageNumber === numberOfPages - 2
    )
      pages = [
        1,
        '...',
        numberOfPages - 3,
        numberOfPages - 2,
        numberOfPages - 1,
        numberOfPages,
      ];
    else
      pages = [
        1,
        '...',
        currentPageNumber - 1,
        currentPageNumber,
        currentPageNumber + 1,
        '...',
        numberOfPages,
      ];
    listOfPages = pages.map((pageNumber, index) => {
      if (pageNumber === '...') {
        return (
          <li
            key={'ellipsis' + index}
            className={cn(
              'flex justify-center items-center w-full',
              'text-SnapGrey400',
              s.border
            )}
          >
            {'...'}
          </li>
        );
      }
      return (
        <li
          data-testid={
            currentPageNumber === pageNumber
              ? 'selected-page'
              : `page-option-${pageNumber}`
          }
          className={cn('flex justify-center items-center w-full', {
            'bg-SnapBlue': currentPageNumber === pageNumber,
            [s.firstItemContainer]: pageNumber === 1 && currentPageNumber === 1,
            [s.lastItemContainer]:
              pageNumber === numberOfPages &&
              currentPageNumber === numberOfPages,
          })}
          key={pageNumber}
        >
          <Link
            href={generateQueryParam(pageNumber)}
            shallow={true}
            className={cn(
              s.border,
              'flex justify-center items-center h-full w-full text-SnapGrey400 secondaryHeavyFont visited:text-SnapGrey400 hover:no-underline hover:text-SnapWhite hover:bg-SnapMediumBlue',
              {
                'text-SnapWhite visited:text-SnapWhite':
                  currentPageNumber === pageNumber,
                [s.firstItem]: pageNumber === 1 && currentPageNumber === 1,
                [s.lastItem]:
                  pageNumber === numberOfPages &&
                  currentPageNumber === numberOfPages,
              }
            )}
            onClick={(_) => {
              setPageNumberAndResetScreen(pageNumber);
            }}
          >
            {pageNumber}
          </Link>
        </li>
      );
    });
  }
  return (
    <ul
      className={cn(
        'flex mb-0 pl-0',
        'flex mb-0 pl-0 list-none',
        variation === 'row' ? 'w-[315px]' : null
      )}
    >
      {currentPageNumber !== 1 && (
        <Link
          href={generateQueryParam(currentPageNumber - 1)}
          shallow={true}
          data-testid="left-pagination-arrow"
          onClick={(_) => {
            setPageNumberAndResetScreen(currentPageNumber - 1);
          }}
          aria-label="left pagination arrow"
          className={cn(
            s.leftArrow,
            'flex justify-center items-center w-full text-SnapGrey400 secondaryHeavyFont visited:text-SnapGrey400 hover:no-underline hover:text-SnapWhite hover:bg-SnapMediumBlue'
          )}
        >
          <ArrowLeftIcon className="w-full" fontSize="small" />
        </Link>
      )}
      {listOfPages}
      {currentPageNumber !== numberOfPages && (
        <Link
          href={generateQueryParam(currentPageNumber + 1)}
          shallow={true}
          data-testid="right-pagination-arrow"
          onClick={(_) => {
            setPageNumberAndResetScreen(currentPageNumber + 1);
          }}
          aria-label="right-pagination-arrow"
          className={cn(
            s.rightArrow,
            'flex justify-center items-center w-full text-SnapGrey400 secondaryHeavyFont visited:text-SnapGrey400 hover:no-underline hover:text-SnapWhite hover:bg-SnapMediumBlue'
          )}
        >
          <ArrowRightIcon className="w-full" fontSize="small" />
        </Link>
      )}
    </ul>
  );
};
