import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { SelectedCategory } from '../components/blog/blog';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';
import { BlogResponse } from '../pages/api/blogs';

interface ResultsType {
  currentPageData: {
    blog_image: PrismicFields.Image;
    summary: PrismicFields.RichTextField;
    topic: PrismicFields.RichTextField;
    release_date: string;
    category: {
      background_color: string;
      slug: string;
    };
    uid: string;
  }[];
  total: number;
  tags: string[];
}

interface FetchBlogsParams {
  tags: string;
  search: string | string[];
  selectedCategory: SelectedCategory;
  pageSize: number;
  pageNumber: number;
  type: string;
}

export const pageSize = 10;

export default function useBlog(
  selectedCategory?: SelectedCategory,
  type?: string
) {
  const router = useRouter();
  const {
    query: { pageNumber: routerPageNumber, search, tags: queryTags },
    isReady,
  } = router;
  const [results, setResults] = useState<ResultsType>({
    total: 0,
    currentPageData: [],
    tags: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const fetchBlogs = useCallback(
    async ({
      tags,
      search,
      selectedCategory,
      pageSize,
      pageNumber,
      type,
    }: FetchBlogsParams): Promise<BlogResponse> => {
      let url = `/api/blogs?pageSize=${pageSize}&pageNumber=${pageNumber}`;
      url += tags ? '&' + 'tags=' + tags : '';
      url += search ? '&' + 'search=' + search : '';
      url += selectedCategory
        ? '&' + 'selectedCategory=' + selectedCategory.id
        : '';
      url += type ? `&type=${type}` : '';

      const response = await fetchWithErrorTracking(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const blogs = await response.json();
      if (response.status !== 200 || blogs.error) {
        return {};
      } else {
        return blogs;
      }
    },
    [fetchWithErrorTracking]
  );

  useEffect(() => {
    if (isReady) {
      setPageNumber(parseInt(routerPageNumber as string) || 1);
      setIsLoading(true);
    }
  }, [routerPageNumber, isReady]);

  useEffect(() => {
    if (pageNumber) {
      const fetchPageData = async () => {
        try {
          const queryResult = await fetchBlogs({
            tags: queryTags as string,
            search,
            selectedCategory,
            pageSize,
            pageNumber,
            type,
          });
          setIsLoading(false);
          const results =
            queryResult?.results?.map((result) => {
              const {
                data: {
                  category,
                  topic,
                  summary,
                  release_date,
                  blog_image,
                },
                uid,
              } = result;
              return {
                category,
                topic,
                summary,
                release_date,
                blog_image,
                uid,
              };
            }) ?? [];
          setResults({
            total: queryResult.total_results_size ?? 0,
            currentPageData: results,
            tags: decodeURIComponent(queryTags as string).split(','),
          });
          // moving the page to top in firefox when the pageNumber is changed
          setTimeout(() => {
            window.scrollTo(0, 0);
          });
        } catch {
          setIsLoading(false);
        }
      };

      fetchPageData();
    }
  }, [pageNumber, selectedCategory, queryTags, search, type, fetchBlogs]);

  return {
    results,
    isLoading,
    pageNumber,
    setPageNumber,
    search,
  };
}
