import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import s from './blog-tags.module.scss';
import cn from 'classnames';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import {
  addParam,
  deselectParam,
  clearQuery,
} from '@ee-monorepo/shared/utilities/functions';
import { Button } from '@ee-monorepo/shared/ui/button';

interface BlogProps {
  documentTagsFromPrismic: string[];
  selectedTagsFromQuery: string[];
}

export const BlogTags = ({
  documentTagsFromPrismic,
  selectedTagsFromQuery,
}: BlogProps) => {
  const isMobile = useHandHeld();
  const router = useRouter();
  const t = useTranslations();
  const listOfTags = documentTagsFromPrismic?.map((tag) => {
    const isSelectedTag =
      selectedTagsFromQuery && selectedTagsFromQuery?.indexOf(tag) > -1;
    return (
      <li
        className={cn(
          s.tagsContentContainer,
          isMobile ? 'secondaryHeavyFont' : 'bodyHeavyFont',
          'pl-1',
          'ml-1',
          isMobile ? 'mb-1' : 'mb-4'
        )}
        key={tag}
      >
        <a
          data-testid={isSelectedTag ? `selected-${tag}` : tag}
          className={cn(isSelectedTag ? s.selectedTag : null, s.tagsContent)}
          href={router.pathname + `?tags=${tag}`}
          onClick={(e) => {
            e.preventDefault();
            isSelectedTag
              ? deselectParam(
                  'tags',
                  tag,
                  router,
                  true,
                  window.location.pathname
                )
              : addParam('tags', tag, router, true, window.location.pathname);
          }}
        >
          #{tag}
        </a>
      </li>
    );
  });
  return listOfTags.length > 0 ? (
    <div>
      <div className={cn(s.blogTagsContainer, 'xs:border-b-0 md:border-b-1')}>
        <div className="md:py-8">
          <div className="flex justify-between">
            <h6 className="bodyHeavyFont mb-2">{t('page.tags')}</h6>
            {selectedTagsFromQuery && (
              <Button
                data-testid="clear-filter-link"
                className={cn(s.clearFilterLink, 'bodyHeavyFont')}
                href={router.pathname}
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  clearQuery('tags', router, true, window.location.pathname);
                }}
              >
                {t('page.clear_filters')}
              </Button>
            )}
          </div>
          {isMobile ? (
            <div className={cn(s.mobileListContainerWrapper, 'ml-1 h-auto')}>
              <ul
                className={cn(s.mobileListContainer, 'pl-0', 'flex flex-wrap')}
              >
                {listOfTags}
              </ul>
            </div>
          ) : (
            <ul className={cn('pl-0', 'flex', 'flex-wrap')}>{listOfTags}</ul>
          )}
        </div>
      </div>
    </div>
  ) : null;
};
