import s from './shared-ui-breadcrumb.module.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cn from 'classnames';
export interface BreadCrumbProps {
  url?: string;
  label: string;
  icon?: boolean;
  active?: boolean;
}
export function BreadCrumb({ url, label, icon, active }: BreadCrumbProps) {
  return (
    <>
      {url ? (
        <a
          className={cn(s.breadcrumb, active || icon ? s.active : '')}
          href={url}
        >
          {label}
        </a>
      ) : (
        <span className={cn(s.breadcrumb, active || icon ? s.active : '')}>
          {label}
        </span>
      )}

      {icon && (
        <span className={cn(s.breadcrumbArrow)}>
          <ChevronRightIcon />
        </span>
      )}
    </>
  );
}
