import cn from 'classnames';
import { Card } from '@ee-monorepo/shared/ui/card';
import { useTranslations } from 'next-intl';

export function NoBlogResults() {
  const t = useTranslations();
  return (
    <div>
      <Card>
        <div className={cn('text-center', 'p-4')}>
          <strong className="mb-2 block">
            {t('shared.blog_input_no_results')}
          </strong>
          <div>
            <div className="secondaryFont">
              {t('shared.no_results_statement_two')}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
