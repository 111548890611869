export const calculateNumberOfPages = (
  totalCount: number,
  pageSize: number
): number => {
  const remainder = totalCount % pageSize;
  const numberOfPagesWithoutRemainder = totalCount / pageSize;
  let numberOfPages;
  if (numberOfPagesWithoutRemainder < 1) {
    numberOfPages = 1;
  } else if (remainder > 0) {
    numberOfPages = Math.ceil(numberOfPagesWithoutRemainder);
  } else {
    numberOfPages = numberOfPagesWithoutRemainder;
  }
  return numberOfPages;
};
